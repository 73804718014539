<style lang="less" scoped>
  .exception-page {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .toolbox {
    padding: 10px;
    background-color: #FFF;
    border-radius: 5px;
  }
  .query-form {
    margin-top: 10px;
  }
  .table {
    margin-top: 10px;
    height: 0;
    flex: 1;
  }
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<style>
  .exception-page .ivu-tag {
    border: none !important;
  }
</style>

<template>
  <div class="exception-page">
    <div class="toolbox">
      <div class="title">
        <fm-title title-text="异常数据" :note-text="'共' + page.total + '项'"></fm-title>
        <Row :gutter="10">
          <i-col><Button :loading="loading.load" icon="md-refresh-circle" @click="loadData">刷新</Button></i-col>
        </Row>
      </div>
      <Row class="query-form" align="middle" :gutter="10">
        <i-col>
          <i-input placeholder="请输入搜索内容" clearable search @on-blur="onSearch" @on-search="onSearch" @on-clear="onSearch" v-model="query.searchKey">
            <Select v-model="query.searchType" slot="prepend" style="width: 80px">
              <Option value="name">名称</Option>
              <Option value="hisCode">his编码</Option>
            </Select>
          </i-input>
        </i-col>
        <i-col>单项奖励: </i-col>
        <i-col>
          <Select></Select>
        </i-col>
      </Row>
    </div>
    <div class="table" ref="table">
      <Table :loading="loading.load" v-if="tableHeight !== null" :height="tableHeight" :columns="columns" :data="dataList"></Table>
    </div>
    <div style="padding: 10px;background-color: #FFF;">
      <Page @on-change="onPageChange" :total="page.total" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
  </div>
</template>

<script>
import { request } from '@/api'

function getDefaultQuery () {
  return {
    searchKey: null,
    searchType: 'name',
    type: 'all',
    trigger: 'all',
    client: 'all'
  }
}

export default {
  data () {
    return {
      dataList: [],
      query: getDefaultQuery(),
      page: {
        size: 10,
        num: 1,
        total: 0
      },
      loading: {
        load: false
      },
      tableHeight: null
    }
  },
  computed: {
    columns () {
      const columns = [
        { key: 'uid', title: 'uid' },
        { key: 'orgId', title: 'orgId' },
        { key: 'roleId', title: 'roleId' },
        { key: 'api', title: 'api' },
        { key: 'params', title: 'params' },
        { key: 'message', title: 'message' },
        { key: 'details', title: 'details' },
        { key: 'clientInfo', title: 'clientInfo' },
        { key: 'line', title: 'line' },
        { key: 'file', title: 'file' },
        { key: 'path', title: 'path' },
        { key: 'triggerType', title: 'triggerType' },
        { key: 'client', title: 'client' },
        { key: 'type', title: 'type' },
        { key: 'time', title: 'time' }
      ]

      return columns
    }
  },
  methods: {
    onSearch (query) {
      if (query) {
        Object.assign(this.query, query)
      }
      this.page.num = 1
      this.loadData()
    },
    onPageChange (num) {
      this.page.num = num
      this.loadData()
    },
    async loadData () {
      if (this.$authFunsProxyNoRoute['exception.get']) {
        const params = {
          pageNum: this.page.num,
          pageSize: this.page.size
        }

        if (this.query.searchKey && this.query.searchType) {
          params[this.query.searchType] = this.query.searchKey
        }

        this.loading.load = true
        try {
          const res = await request('/ucenter/exception_record', 'get', params)
          this.page.total = res.total
          this.dataList = res.data
        } catch (e) {
          console.error(e)
        }
        this.loading.load = false
      }
    },
    resize () {
      this.tableHeight = this.$refs.table.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
}
</script>